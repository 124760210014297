.bg-img{
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.352), rgba(0, 0, 0, 0.21)),url('../Assets/dragon-ball.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
/* Black gradient at the top and bottom */
.bg-img:before, .bg-img:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 20%;
    left: 0;
    z-index: 999;
}

.bg-img:before {
    top: 0;
    background: linear-gradient(to bottom, black, transparent);
}

.bg-img:after {
    bottom: 0;
    background: linear-gradient(to top, black, transparent);
}
.sponsors{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
}
.head-spon{
    margin-top: 80px;
    z-index: 999;
}
.associate{
    display: flex;
    justify-content: center;
}
.gold{
    display: flex;
    justify-content: center;
}
.bronze{
    display: flex;
    justify-content: center;
}
.silver{
    display: flex;
    justify-content: center;
}
.voucher{
    display: flex;
    justify-content: center;
    z-index: 1000;
}
.sponsor{
    background-color: white;
    padding: 7px 10px 0 10px;
    margin: 10px;
    border-radius: 10px;
    margin-top: 20px;
}
@media screen and (max-width: 768px){
    .sponsor img,.sponsor a img{
        height: 50px;
    }    
}
@media screen and (max-width: 380px){
    .sponsor img,.sponsor a img{
        height: 40px;
    }    
}