/* General styles for the footer */
.footer {
    background-image: url(../Assets/knowBG.png); /* Replace with your image URL */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    color: white;
    padding: 2rem 0;
    height: 80%;
    position: relative;
}

/* Black gradient at the top and bottom of the footer */
.footer:before, .footer:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 20%; /* Adjust the height of the gradient */
    left: 0;
    z-index: 1;
}

.footer:before {
    top: 0;
    background: linear-gradient(to bottom, black, transparent);
}

.footer:after {
    bottom: 0;
    background: linear-gradient(to top, black, transparent);
}

/* Container for footer content */
.footer-container {
    padding: 3rem 0;
    z-index: 2; /* Ensures content is above the gradients */
    position: relative;
}

/* Flexbox container to center the columns */
.footer-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Video wrapper for responsive iframe */
.video-wrapper {
    display: inline-block; /* Make it behave like an inline element */
    position: relative;
    width: 70%; /* Adjust width to container */
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    margin: 0 auto;
    box-sizing: border-box; /* Ensures padding is included in width calculation */
}

/* Styling the iframe */
.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px; /* Optional: Adds rounded corners */
}

/* General styles for paragraph text */
p {
    font-family: got;
    text-shadow: 0px 0px 15px black;
    font-size: 2em;
    margin-bottom: 2rem;
}

/* Responsive adjustments for different screen sizes */

/* Tablet view and below (max-width: 768px) */
@media (max-width: 768px) {
    .video-wrapper {
        max-width: 90%; /* Smaller width for tablets */
        padding-left: 10px;
        padding-right: 10px;
    }

    p {
        font-size: 1.8em;
    }
}

/* Mobile view (max-width: 480px) */
@media (max-width: 480px) {
    .video-wrapper {
        max-width: 100%; /* Full width for small screens */
        padding-left: 5px;
        padding-right: 5px;
    }

    p {
        font-size: 1.5em;
    }
}