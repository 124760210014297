*{
  margin: 0;
  padding: 0;
  font-family: got, sans-serif;
}

.footer-bg{
  display: flex;
  align-items: center;
  padding: 30px 0px;
  justify-content: space-evenly;
  background-color: black;
}

.clg {
  display: flex;
  padding: 10px;
}
.sai-footer{
  height: 40px;
  margin-right: 10px;
}

.clg-name{
  font-size: 16px;
  display: flex;
  color: #32A3FF;
  align-items: center;
}

.mail {
  display: flex;
  padding: 10px;
}
.mail-logo {
  height: 35px;
  margin-left: 5px;
  margin-right: 10px;
}
.mail-id {
  font-size: 16px;
  display: flex;
  align-items: center;
}
.mail-id span {
  font-family: sans-serif;
  font-size: 16px;
}

.contact {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 20px;
}

.contact a {
  margin-right: 50px;
}

.contact img {
  width: 25px; 
  height: auto;
}

.co-heading{
  font-size: 20px;
  margin-top: 7px;
  margin-bottom: 7px;
}
.co-name {
  color: gray;
  margin-left: 10px;
  margin-top: 5px;
  font-size: 16px;
}

/* Footer bottom section */
.footer-bottom {
  font-family: got;
  text-align: center;
  padding: 10px;
  background-color: #000000; /* Dark background to match footer */
  color: #fff; /* White text for contrast */
}

.footer-divider {
  border: none;
  border-top: 2px solid #fff; /* White horizontal ruler */
  margin: 20px 0;
}

.footer-text {
  font-size: 1rem;
  font-family: got; /* Matching font */
}

.footer-text span {
  font-size: 1.2rem;
}
/* Footer bottom section */

@media (max-width: 1024px) {
  .footer-bg {
    flex-direction: row;
    align-items: flex-start;
  }

  .clg-det, .coordinators-det, .map-container {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .contact {
    margin-top: 20px;
    justify-content: flex-start;
  }
}

@media (max-width: 768px) {
  .footer-bg {
    flex-direction: column;
    align-items: center;
  }

  .clg,.mail{
    width: 100%;
    display: inline;
    align-items: center ;
  }

  .contact {
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
  }

  .contact a {
    margin: 5px 0;
  }

  .map-container iframe {
    height: 250px;
  }
}

@media (max-width: 480px) {
  .sai-footer, .mail-logo {
    display: flex;
    align-items: center;
    }
  
  .footer-bg{
    padding: 0px;
  }

  .contact img {
    width: 20px; 
  }

  .clg{
    display: flex;
    padding: 5px;
  }
  .mail {
    display: flex;
    padding: 5px;
  }
  .contact {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }
  .contact a{
    margin-right: 50px;
  }
  .coordinators-det{
    display: flex;
  }
  .co-staff{
    margin-right: 10px;
  }
  .co-heading{
    font-size: 14px;
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .co-name {
    color: gray;
    margin-left: 10px;
    margin-top: 5px;
    font-size: 12px;
  }

  .map-container iframe {
    height: 200px; 
  }
}