/* Resetting styles for a consistent look */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Background styles for the main container with gradient */
.bg {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.352), rgba(0, 0, 0, 0.21)),
                      url(../Assets/naruto_bg1.png);
    background-size: cover;         /* Ensures the image covers the entire element */
    background-position: center;    /* Centers the image */
    background-repeat: no-repeat;   /* Prevents the image from repeating */
    height: 100vh;                  /* Ensures the background covers the full viewport height */
    width: 100%;                    /* Ensures the background covers the full width */
    display: flex;
    flex-direction: column;
    align-items: center;            /* Centers content horizontally */
    position: relative;             /* Position relative for the timer positioning */
}

/* Heading styles */
.heading {
    font-size: 15rem;
    margin-top: 50px;
    color: white;
    text-shadow: 0px 0px 15px black;
}

/* Styles for jap-text container */
.jap-text {
    display: flex;
    align-items: center;
}

/* Technology section styles */
.technology {
    margin-right: 50px;
}

/* Styles for technology div */
.technology div {
    margin-bottom: 10px;
    font-size: 40px;
    text-shadow: 0px 0px 15px black;
}

/* Jap-number section styles */
.jap-number {
    margin-left: 50px;
}

/* Styles for jap-number div */
.jap-number div {
    font-size: 25px;
    text-shadow: 0px 0px 15px black;
}

/* Register button styles */
.register {
    background-color: #FDE46E;
    font-family: got;
    color: black;
    font-size: 20px;
    margin-left: 50px;
    margin-right: 50px;
    border-radius: 10px;
    border: rgb(72, 70, 70) 2px solid;
    padding: 10px;
    /* cursor: pointer; */
}

/* Register link styles */
.register a {
    color: black;
    background-color: transparent;
    text-decoration: none;
}/* Styles for the timer container */
.timer {
    position: absolute;              /* Absolute positioning to place it at the bottom */
    bottom: 0;
    margin-bottom: 50px;
    width: 100%;
    display: flex;
    justify-content: center;        /* Center the timer horizontally */
    padding: 20px 0;                /* Optional padding */
}

/* Timer styles for each time unit */
.timer .time {
    font-family: got;
    display: flex;
    flex-direction: column;          /* Align time unit and label vertically */
    align-items: center;
    margin: 0 10px;               /* Spacing between time units */
    color: white;
    text-shadow: 0px 0px 10px black;
}

.timer h1 {
    font-size: 40px;
    margin: 0;
    text-shadow: 0px 0px 15px black;
}

.timer p {
    font-size: 30px;
    margin: 0;
    text-shadow: 0px 0px 15px black;
}

/* Black gradient at the top and bottom */
.bg:before, .bg:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 20%;
    left: 0;
    z-index: 999;
}

.bg:before {
    top: 0;
    background: linear-gradient(to bottom, black, transparent);
}

.bg:after {
    bottom: 0;
    background: linear-gradient(to top, black, transparent);
}
/* Responsive styles */

@media (max-width: 1250px) {
    .heading {
        font-size: 13rem;
        margin-top: 80px;
    }
    .timer p {
        font-size: 30px;
    }
    .timer h1 {
        font-size: 40px;
    }
    .technology div {
        font-size: 30px;  
    }
    .jap-number div {
        font-size: 25px;
    }
    .register a{
        font-size: 18px;
    }
}

@media (max-width: 1190px) {
    .heading{
        margin-top: 80px;
    }
    .timer p {
        font-size: 20px;
    }
    .timer h1 {
        font-size: 40px;
    }
}

@media (max-width: 990px) {
    .heading {
        font-size: 11rem;
        margin-bottom: 50px;
    }
    .technology div {
        font-size: 30px;  
    }
    .jap-number div {
        font-size: 25px;
    }
}
@media (max-width:850px){
    .heading{
        margin-top: 50px;
    }
}
@media (max-width: 750px) {
    .heading {
        font-size: 9rem;
        margin-bottom: 100px;
    }
    .timer p {
        font-size: 25px;
    }
    .timer h1 {
        font-size: 30px;
    }
}
@media (max-width:600px){
    .heading{
        font-size: 8.5rem;
        margin-top: 80px;
        margin-bottom: 50px;
    }
}
@media (max-width:530px) {
    .heading{
        font-size: 7.5rem;
        margin-top: 100px;
        margin-bottom: 60px;
    }
    .register{
        margin-left: 10px;
        margin-right: 10px;
    }  
}
@media (max-width:470px){
    .heading{
        font-size: 6.5rem;
        margin-top: 100px;
    }
}
@media (max-width: 450px) {
    .heading {
        font-size: 5.5rem;
        margin-top: 120px;
        margin-bottom: 80px;
    }
    .bg {
        background-image: url(../Assets/naruto_bg1_mob.png);
    }
    .timer {
        padding: 20px 0;
        position: absolute;
        bottom: 0;
        left: 0;
        margin-bottom: 100px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .timer p {
        font-size: 20px;
    }
    .timer h1 {
        font-size: 25px;
    }
    .register {
        margin-left: 0px;
        padding: 7px;
        font-size: 1rem;
        margin-right: 0px;
    }
    .register a{
        font-size: 14px;
    }
    .technology{
        margin-right: 20px;
    }
    .technology div {
        font-size: 25px;
    }
    .jap-number{
        margin-left: 20px;
    }
    .jap-number div {
        font-size: 20px;
    }
}