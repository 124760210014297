.team-det{
    margin: 0;
    padding-top: 70px;
    display: flex;
    background: url('../Assets/img/teambg1.png') no-repeat center center fixed;
    font-size: 16px; /* Set a base font size for scaling */
}

.p-carousel {
    width: 100%;
    display: flex;
    justify-content: center;
}
.p-carousel-item {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
}
.p-icon {
    /* display: none; */
    color: black;
    cursor: pointer;
    transition: transform 0.3s ease;
}
.card {
    margin: 20px;
    padding: 20px;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 90%; /* Limit the max width */
}
.card h3 {
    margin-bottom: 20px;
    font-size: 2em; /* Adjusted for scaling */
    color: red;
    text-align: center;
}
.tech{
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 2em; /* Adjusted for scaling */
    color: red;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    text-align: center;
}
.team-det{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.member-card {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin-bottom: 15px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.member-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.member-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.member-image-container {
    margin-bottom: 15px;
}

.member-image {
    border-radius: 50%;
    width: 200px; /* Adjusted for smaller screens */
    height: 200px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.member-details {
    margin-top: 10px;
}

.member-name {
    margin: 5px 0;
    font-size: 1.25em; /* Adjusted for scaling */
    color: #f7e8e8;
    font-weight: bold;
}

.member-role {
    margin: 5px 0;
    font-size: 1em; /* Adjusted for scaling */
    color: red;
}

.member-team {
    margin: 5px 0;
    font-size: 0.875em; /* Adjusted for scaling */
    color: #888;
}


/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .member-card {
        /* max-width: 200px; */
    }

    .card h3 {
        font-size: 1.5em;
    }

    .member-image {
        width: 150px;
        height: 150px;
    }

    .member-name {
        font-size: 1.1em;
    }

    .member-role {
        font-size: 0.9em;
    }

    .member-team {
        font-size: 0.8em;
    }
}

@media (max-width: 480px) {
    .card h3 {
        font-size: 1.2em;
    }

    .member-image {
        width: 150px;
        height: 150px;
    }

    .member-name {
        font-size: 1em;
    }

    .member-role {
        font-size: 0.8em;
    }

    .member-team {
        font-size: 0.7em;
    }
}
