/* Resetting styles for a consistent look */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Main container with black gradients at the top and bottom */
.parent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px;
  position: relative;
}

.parent:before, .parent:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 20%; /* Adjust the height of the gradient */
  left: 0;
  z-index: 1;
}

.parent:before {
  top: 0;
  background: linear-gradient(to bottom, black, transparent);
}

.parent:after {
  bottom: 0;
  background: linear-gradient(to top, black, transparent);
}

/* Wrapper to center content and maintain structure */
.content-wrapper {
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  flex-direction: row; /* Default layout for larger screens */
  z-index: 2; /* Ensures content is above the gradients */
}

.logo-holder {
  margin-right: 20px; /* Adjust space between logo and content as needed */
  position: relative;
}

.logo-holder img {
  width: 200px; /* Adjust size as needed */
  transition: transform 0.3s ease, filter 0.3s ease; /* Smooth transition for scaling and highlighting */
}

.logo-holder img:hover {
  transform: scale(1.1); /* Slightly enlarge the logo on hover */
  filter: brightness(1.2); /* Increase brightness on hover for a highlighting effect */
}

.content {
  max-width: 1000px; /* Adjust width as needed */
  font-size: 0.8rem;
  line-height: 1.5;
}
.content span{
  color:red;
}
/* Responsive Styles */
@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column; /* Stack logo and content vertically */
    text-align: center;
  }

  .logo-holder {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .logo-holder img {
    width: 150px; /* Scale down the logo for smaller screens */
  }

  .content {
    font-size: 0.9rem; /* Adjust text size for smaller screens */
    line-height: 1.4;
    max-width: 90%; /* Allow more space for content on smaller screens */
  }
}

@media (max-width: 480px) {
  .content-wrapper {
    padding: 10px;
  }

  .logo-holder img {
    width: 120px; /* Further reduce logo size on very small screens */
  }

  .content {
    font-size: 0.8rem; /* Adjust text size for very small screens */
    line-height: 1.3;
  }
}