/* Container for the entire page */
.parent-root {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)), url('../Assets/eventPgBg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    text-align: center;
    padding: 150px 20px 50px;
}

/* Glassmorphic effect for the text background */
.text-bg {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    margin-top: 20px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    margin-top: 30px;
    width: 100%;
    margin-bottom: 20px;
    max-width: 500px;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
    .category-buttons {
        flex-direction: column;
        gap: 10px;
    }

    .category-btn {
        width: 100%;
        max-width: 300px;
    }
}

/* Container for the registration section */
.registration-ribbon {
    margin-top: 20px;
}

/* Styling for the text in the registration section */
.register-text p {
    margin: 0;
    font-size: 1.5rem;
    color: white;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.8);
}

/* Container for the button */
.register-btn {
    margin-top: 10px;
}

/* Button styling */
.register-btn div {
    background-color: #f34242;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: none;
    font-size: 1rem;
    transition: background-color 0.3s;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

/* Button hover effect */
.register-btn button:hover {
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.8);
    background-color: #e53e3e;
}
