
.top-nav{
    display: flex;
    position: fixed;
    justify-content: center;
    width: 100%;
    height: 70px;
    background: linear-gradient(135deg,rgba(255, 255, 255, 0.2),rgba(255,255,255,0.2));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px 0 rgba(0,0,0, 0.37);
    z-index:1001;
}
.logo-year{
    display: flex;
    margin: 10px;
    margin-left: 20px;
    position: fixed;
    top: 0;
    left: 0;
}
.logo-year img {
    height: 3rem;                  
    filter: drop-shadow(0px 0px 10px red);
}
.year-2k24 {
    font-weight: bold;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-left: 10px;
    color: red;
    text-shadow: 0px 0px 6px red;
}
.nav{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    width: 70%;
}
.nav li{
    list-style: none;
}
.nav li a{
    text-decoration: none;
    color: #b0b0b0;
    font-size: 1.5rem;
    filter: drop-shadow(0px 0px 4px black);
}

.nav li a:after{
    content: "";
    position: absolute;
    background: red;
    height: 3px;
    width:0;
    left: 0;
    bottom: -5px;
    transition: 0.3s;
}
.nav li a:hover{
    color: white;
}
.nav li a:hover:after{
    width: 100%;
}
.menu-icon{
    display: none;
}
.menu{
    display: none;
}
@media (max-width: 1190px){
    .logo-year{
        padding-top: 5px;
    }
    .logo-year img{
        height: 2.5rem;
    }
    .year-2k24{
        font-size: 1.8rem;
    }
}
@media (max-width: 850px){
    .nav li{
        display: none;
    }
    .burger-menu{
        display: block;
        margin: 20px;
        position: fixed;
        right: 0;
        cursor: pointer;
    }
    .burger-bar{
        width: 30px;
        border-radius: 0.5rem;
        border: 0.01rem solid white;
        height: 5px;
        background: red;
        filter: drop-shadow(0px 0px 3px red);
        margin: 5px;
    }
    .menu{
        position: absolute;
        right: 0;
        width: 40%;
        height: 100vh;
        background-color: black;
        box-shadow: 0px 20px 30px 0 rgba(0,0,0, 0.97);
        z-index: -1;
        display: block;
        padding-top: 80px;
        justify-content: space-evenly;
    }
    .menu div{
        width: 100%;
    }
    .menu li{
        list-style: none;
        padding: 10px;
        padding-left: 30px;
    }
    .menu li a{
        text-decoration: none;
        color: white;
        font-size: 1.2rem;
    }
    .menu li a:hover{
        color: red;
    }
    .hidden{
        display: none;
    }
    .visible{
        display: inherit;
    }
    .burger-bar.clicked:nth-child(1){
        transform: rotate(45deg) translate(0.1em,0.4em);
        transition: ease-out 0.5s;
    }
    .burger-bar.clicked:nth-child(2){
        transform: scale(0.01);
        transition: ease-out 0.5s;
    }
    .burger-bar.clicked:nth-child(3){
        transform: rotate(135deg) translate(-0.5em,0.75em);
        transition: ease-out 0.5s;
    }
    .burger-bar.unclicked{
        transform: rotate(0) translate(0);
        transition: cubic-bezier(0.175,0.885,0.32,1.275) 0.5s;
    }
}